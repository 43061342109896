<template>
    <v-container grid-list-md pa-0 relative class="page-layout" style="border-top:10px solid #e3e3e3;">
        <v-layout row wrap>
            <v-flex xs12 sm12>
                <v-card>
                    <v-card-title class="title">
                        <v-icon>layers</v-icon> &nbsp;Fiscal Year
                        <v-spacer></v-spacer>
                        <add-button permission="grade-create" @action="form.dialog = true">New Fiscal Year</add-button>
                    </v-card-title>

                    <v-data-table
                            :headers="headers"
                            hide-actions
                            :items="form.items.data"
                            :search="search"
                            :pagination.sync="pagination"
                            :total-items="form.items.meta.total"
                            :loading="form.loading">
                        <template slot="items" slot-scope="props">
                            <td>{{ props.index + form.items.meta.from }}</td>
                            <td class="text-xs-left">
                                {{props.item.name}}
                                <!--{{ props.item.name_np.name }}-->
                            </td>

                            <td class="text-xs-left">
                                {{ props.item.start_np }}
                                <div>
                                    ({{ props.item.start_eng }})
                                </div>
                            </td>
                            <td class="text-xs-left">
                                <!--{{ props.item.name }}-->
                                {{ props.item.end_np }}
                                <div>
                                    ({{ props.item.end_eng }})
                                </div>
                            </td>
                            <!--<td class="text-xs-left"> {{ props.item.name_np.end_date }}-->
                            <!--<div><span>{{ props.item.name_en.end_date }}</span></div>-->
                            <!--</td>-->

                            <td class="text-xs-right">
                                <edit-button permission="subject-read"
                                             @agree="form.edit(props.item),editData(props.item)"/>
                                <!-- <delete-button permission="grade-create" @agree="form.delete(props.item.id)"/> -->
                            </td>
                        </template>
                        <v-alert slot="no-results" :value="true" color="error" icon="warning">
                            Your search for "{{ search }}" found no results.
                        </v-alert>
                    </v-data-table>
                </v-card>
            </v-flex>
            <v-dialog v-model="form.dialog" persistent max-width="500px">
                <v-card>
                    <v-card-title class="primary white--text">
                        <span class="title">Add/Update</span>
                    </v-card-title>
                    <v-card-text class="pb-1">
                        <v-form ref="form" @submit.prevent="save"
                                @keydown.native="form.errors.clear($event.target.name)" @keyup.enter="save"
                                lazy-validation>
                            <v-text-field autocomplete="off" label="Fiscal Year Name*" required class="pa-0 pt-3"
                                          v-model="form.name" name="name"
                                          :error-messages="form.errors.get('name_np')"
                                          hint="2075/76"/>

                            <v-select v-if="!form.id" v-model="start_date" :items="fiscalYearList"
                                      label="Select Starting Fiscal Year"
                                      name="fiscal_year" :height="25" :readonly="form.loading"
                            />

                            <p v-if="form.start_date">Fiscal Year Start Date :<span style="color: green">{{form.start_date}} ({{start_date_eng}}) </span> </p>
                            <p v-if="form.end_date">Fiscal Year End Date : <span style="color: green">{{form.end_date}} ({{end_date_eng}})</span></p>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="warning" outline @click="form.dialog = false, form.reset()">Close</v-btn>
                        <v-btn color="success" outline @click="save">Save</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-layout>
    </v-container>
</template>
<script>
    import {mapState, mapMutations} from 'vuex';

    const NepaliDate = require('nepali-date');
    const nd = new NepaliDate();

    import Form from '@/library/Form'

    export default {
        data: (vm) => ({
            form: new Form({
                name: '',
                start_date: '',
                end_date: ''
            }, '/api/fiscal-year'),
            search: null,
            pagination: {
                rowsPerPage: 10,
                sortBy: 'id'
            },
            headers: [
                {text: '#', align: 'center', value: 'id', width: 50, sortable: false},
                {text: 'Fiscal Year', align: 'left', value: 'name_np'},
                {text: 'Start Date', align: 'left', value: 'name_en'},
                {text: 'End Date', align: 'left', value: 'name_en'},
                // {text: 'Date', align: 'left', value: 'description'},
                {text: 'Action', align: 'right', sortable: false, width: 200}
            ],
            start_date: '',
            start_date_eng: '',
            end_date_eng: '',
            // date: new Date().toISOString().substr(0, 10),
            // end_date: new Date().toISOString().substr(0, 10),
            // dateFormatted: '',
            // endDateFormatted:'',
            fiscalYears: [],
            fiscalYearList: [nd.format('YYYY') - 1, nd.format('YYYY'), parseFloat(nd.format('YYYY')) + 1],
            currentFiscalYear: {},
        }),

        computed: {
            ...mapState(['batch']),
            computedDateFormatted() {
                return this.formatDate(this.date)
            },
            computedEndDateFormatted() {
                return this.formatDate(this.end_date)
            }
        },

        watch: {
            'pagination': function () {
                this.get();
            },
            'batch': function (value) {
                this.get();
            },
            'start_date': function (val) {
                this.form.start_date = val + '-04' + '-01';
                this.form.end_date = parseFloat(val) + 1 + '-03' + '-31';
                let sdEng = new NepaliDate(this.form.start_date).getEnglishDate();
                let edEng = new NepaliDate(this.form.end_date).getEnglishDate();
                this.start_date_eng = sdEng.getFullYear() + '-' + sdEng.getMonth().pad() + '-' + sdEng.getDay().pad();
                this.end_date_eng = edEng.getFullYear() + '-' + edEng.getMonth().pad() + '-' + edEng.getDay().pad();

            },
        },

        methods: {
            ...mapState(['accounts']),

            ...mapMutations(['SET_FISCAL_YEAR', 'SET_FISCAL_YEARS_ALL']),
            queryString() {
                let json = this.pagination;
                return '?' +
                    Object.keys(json).map(function (key) {
                        if (![null, undefined].includes(json[key]))
                            return encodeURIComponent(key) + '=' + encodeURIComponent(json[key]);
                    }).join('&');
            },
            get(params) {
                let query = [null, undefined].includes(params) ? this.queryString() : params;
                this.form.get(null, query).then(({data}) => {
                    this.pagination.totalItems = data.meta.total
                })
            },

            save() {
                this.form.batch_id = this.batch.id;
                this.form.store().then(data => {
                    this.getFiscalYear();

                    // this.$events.fire('notification', {message: data.data.message, status: 'success'});
                }).catch(e => {
                    this.$events.fire('notification', {message: e.response.data.message, status: 'error'})
                });
            },


            viewGrade(id) {
                this.$router.push({
                    name: 'grades-detail',
                    params: {
                        gradeId: id
                    }
                })
            },
            formatDate(date) {
                if (!date) return null

                const [year, month, day] = date.split('-')
                return `${month}/${day}/${year}`
            },
            parseDate(date) {
                if (!date) return null

                const [month, day, year] = date.split('/')
                return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
            },
            editData(item) {
                this.form.name = item.name;
                this.form.start_date = item.start_np;
                this.form.end_date = item.end_np;
                this.start_date_eng = item.start_eng;
                this.end_date_eng = item.end_eng;

            },
            getFiscalYear() {
                // console.log('testing....fiscal Year');

                this.$rest.get('/api/fiscal-year').then(({data}) => {
                    // console.log('get... Fiscal Year');
                    let currentFiscalYear = null;
                    this.fiscalYears = data.data.map(item => {
                        if (item.is_current === true) {
                            currentFiscalYear = {id: item.id, text: item.name};
                        }
                        return {id: item.id, text: item.name};
                    });
                    this.SET_FISCAL_YEARS_ALL(this.fiscalYears);


                    let savedFiscalYear = window.localStorage.getItem('_currentFiscalYear');
                    if (savedFiscalYear) {
                        currentFiscalYear = JSON.parse(savedFiscalYear);
                    }

                    if (currentFiscalYear)
                        this.SET_FISCAL_YEAR(currentFiscalYear);

                    location.reload();

                }).catch(err => {
                    this.$events.fire('notification', {message: err.response.data.message, status: 'error'});
                });
            },
            selectFiscalYear(id) {
                let fiscalYear = this.fiscalYears.find(item => item.id === id);
                this.SET_FISCAL_YEAR(fiscalYear);
                location.reload();
            },
        }
    }
</script>
<style lang="scss">

    /*.page-layout {*/

    /*}*/


</style>